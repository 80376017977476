import React from "react"
import PropTypes from "prop-types"
import Image from "../components/image"

import { RichText } from "prismic-reactjs"
import linkResolver from "../utils/linkResolver"

const QuoteSlice = ({ slice }) => {
  return (
    <section className="section">
      <div className="box">
        <article className="media">
          {slice.image_author && (
            <div className="media-left">
              <figure className="image is-64x64">
                <Image
                  sharp={slice.image_authorSharp}
                  image={slice.image_author}
                />
              </figure>
            </div>
          )}
          <div className="media-content">
            <div className="content">
              <blockquote>
                {RichText.render(slice.quote, linkResolver)}

                <footer>
                  —{RichText.render(slice.name_of_the_author, linkResolver)}
                </footer>
              </blockquote>
            </div>
          </div>
        </article>
      </div>
    </section>
  )
}

export default QuoteSlice

QuoteSlice.propTypes = {
  slice: PropTypes.object.isRequired,
}
