import React from "react"
import PropTypes from "prop-types"

import { RichText } from "prismic-reactjs"

const RichTextSlice = ({ slice }) => {
  return slice?.is_bold ? (
    <div className="hasRichText box is-theme2">
      <RichText render={slice.text} />
    </div>
  ) : (
    <div className="hasRichText">
      <RichText render={slice?.text} />
    </div>
  )
}

export default RichTextSlice

RichTextSlice.propTypes = {
  slice: PropTypes.object.isRequired,
}
