import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import { RichText } from "prismic-reactjs"
import { TwitterTweetEmbed } from "react-twitter-embed"
import SliceRichText from "../slices/richtext"
import SliceQuote from "../slices/quote"
import SliceSection from "../slices/section"
import SliceImage from "../slices/image"
import SliceForm from "../slices/form"
import InlineBlock from "../slices/inlineBlock"
import { ArrowScrollToTop } from "../components/arrowScrollToTop"

function Lead({ data, pageContext: { locale, uid }, location }) {
  const leadData = data.prismic.leadpage
  return (
    <>
      <SEO
        title={leadData.meta_title}
        desc={leadData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: leadData.title,
          subtitle: leadData.subtitle,
          image: {
            image: leadData.main_image,
            sharp: leadData.main_imageSharp,
          },
        }}
      />

      <section>
        <div className="container">
          <div className="columns is-centered">
            <article className="column is-10 hasRichText">
              {leadData.richtext && <RichText render={leadData.richtext} />}
              {leadData.body &&
                leadData.body.map((slice, i) => {
                  switch (slice.__typename) {
                    case "PRISMIC_PostBodyTweet":
                      return (
                        <TwitterTweetEmbed
                          key={i}
                          tweetId={slice.primary.tweet_id}
                        />
                      )
                    case "PRISMIC_LeadpageBodyText":
                      return (
                        <SliceRichText key={i}>
                          {slice.primary.text}
                        </SliceRichText>
                      )
                    case "PRISMIC_LeadpageBodyQuote":
                      return (
                        <SliceQuote key={i} slice={slice.primary}></SliceQuote>
                      )
                    case "PRISMIC_LeadpageBodyBanner_with_caption":
                      return <SliceSection key={i} slice={slice.primary} />
                    case "PRISMIC_LeadpageBodyImage":
                      return <SliceImage key={i} slice={slice.primary} />
                    case "PRISMIC_LeadpageBodyForm":
                      return <SliceForm key={i} slice={slice} />
                    case "PRISMIC_LeadpageBodyInline_blocks":
                      return <InlineBlock key={i} slice={slice} />
                    default:
                      break
                  }
                })}
            </article>
          </div>
        </div>
      </section>
      <ArrowScrollToTop />
    </>
  )
}

export default Lead

export const leadQuery = graphql`
  query leadQuery($uid: String!, $locale: String!) {
    prismic {
      leadpage(uid: $uid, lang: $locale) {
        ...Lead
      }
    }
    site {
      ...SiteInformation
    }
  }
`
