import React from "react"
import PropTypes from "prop-types"
import Image from "../components/image"
import LocalizedLink from "../components/localizedLink"

import { RichText } from "prismic-reactjs"

const SectionSlice = ({ slice }) => {
  return (
    <section className="hero is-theme1">
      <div className="hero-body">
        <div className="container">
          <div className="media">
            {slice.image_banner && (
              <figure className="media-left">
                <div className="image is-128x128">
                  <Image
                    sharp={slice.image_bannerSharp}
                    image={slice.image_banner}
                  />
                </div>
              </figure>
            )}
            <div className="media-content">
              <div className="content">
                <h1 className="title">
                  {RichText.asText(slice.title_of_banner)}
                </h1>
                <h2 className="subtitle">
                  {RichText.asText(slice.description)}
                </h2>
                {slice.button_link &&
                  slice.button_link._linkType === "Link.web" && (
                    <a
                      href={slice.button_link.url}
                      target={slice.button_link.target}
                      className="button is-rounded button__primary has-text-white"
                    >
                      {slice.button_label}
                    </a>
                  )}
                {slice.button_link &&
                  slice.button_link._linkType === "Link.document" && (
                    <LocalizedLink
                      to={slice.button_link._meta.uid}
                      className="button is-rounded button__primary has-text-white"
                    >
                      {slice.button_label}
                    </LocalizedLink>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionSlice

SectionSlice.propTypes = {
  slice: PropTypes.object.isRequired,
}
