import React from "react"
import PropTypes from "prop-types"
import Image from "../components/image"
import LocalizedLink from "../components/localizedLink"
import linkResolver from "../utils/linkResolver"

import { RichText } from "prismic-reactjs"

const InlineBlock = ({ slice }) => {
  return (
    <section className="section">
      <div className="container">
        <h2 className="title is-3">
          {RichText.asText(slice.primary.inline_title)}
        </h2>
        <div className="columns is-multiline">
          {slice.fields.map(block => (
            <div key={block.inline_block_id} className="column is-6">
              {block.inline_block_title && (
                <h3 className="title is-4">
                  {RichText.asText(block.inline_block_title)}
                </h3>
              )}
              {block.inline_block_image && (
                <Image
                  sharp={block.inline_block_imageSharp}
                  image={block.inline_block_image}
                />
              )}
              {block.inline_block_richtext &&
                RichText.render(block.inline_block_richtext, linkResolver)}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default InlineBlock

InlineBlock.propTypes = {
  slice: PropTypes.object.isRequired,
}
