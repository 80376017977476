import React from "react"
import PropTypes from "prop-types"
import Image from "../components/image"

const ImageSlice = ({ slice }) => {
  return (
    <div className="container hasRichText">
      <div className="columns is-centered">
        <div className="column">
          <figure className="image">
            <Image sharp={slice.imageSharp} image={slice.image}/>
            {slice.caption && <p>{slice.caption}</p>}
          </figure>
        </div>
      </div>
    </div>
  )
}

export default ImageSlice

ImageSlice.propTypes = {
  slice: PropTypes.object.isRequired,
}
